exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-for-contract-tsx": () => import("./../../../src/pages/service-for-contract.tsx" /* webpackChunkName: "component---src-pages-service-for-contract-tsx" */),
  "component---src-pages-services-development-tsx": () => import("./../../../src/pages/services/development.tsx" /* webpackChunkName: "component---src-pages-services-development-tsx" */),
  "component---src-pages-services-html-mail-tsx": () => import("./../../../src/pages/services/html-mail.tsx" /* webpackChunkName: "component---src-pages-services-html-mail-tsx" */),
  "component---src-pages-services-seo-tsx": () => import("./../../../src/pages/services/seo.tsx" /* webpackChunkName: "component---src-pages-services-seo-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-website-tsx": () => import("./../../../src/pages/services/website.tsx" /* webpackChunkName: "component---src-pages-services-website-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-archive-tsx": () => import("./../../../src/templates/blog-post-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-voice-detail-tsx": () => import("./../../../src/templates/voice-detail.tsx" /* webpackChunkName: "component---src-templates-voice-detail-tsx" */),
  "component---src-templates-works-detail-tsx": () => import("./../../../src/templates/works-detail.tsx" /* webpackChunkName: "component---src-templates-works-detail-tsx" */)
}

